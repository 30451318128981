<template>
    <footer id="options" class="footer">
        <div class="container">
            <p>
                Copyright © 2021-{{ thisYear() }} Andy P.
                <span class="mx-4 is-ghost">|</span>
                <a class="px-2" href="https://mastodon.art/@otterlove" title="Mastodon" target="_blank"><i
                        class="fa-brands fa-mastodon"></i></a>
                <a class="px-2" href="https://www.tumblr.com/abocode" title="Tumblr" target="_blank"><i
                        class="fa-brands fa-tumblr"></i></a>
                <a class="px-2" href="https://www.pillowfort.social/AndyP" title="Pillowfort" target="_blank"><i
                        class="fa-solid fa-p"></i></a>
            </p>
        </div>
    </footer>
</template>

<script setup>
function thisYear() {
    const d = new Date();
    return d.getFullYear();
}
</script>
<template>
    <section class="section">
        <div class="container">
            <slot name="content"
                  @openImage="openImage"></slot>

            <div class="mt-6 buttons">
                <router-link to="/"
                             class="button is-primary is-outlined">
                    <img class="is-icon mr-2"
                         src="@/assets/img/favicon.png"
                         style="width: 1.25em" />
                    Home
                </router-link>
                <router-link :to="{ name: 'About', hash: anchor }"
                             class="button is-primary is-outlined">
                    Return to About
                </router-link>
            </div>
        </div>
    </section>
    <Footer />
</template>

<script>
import Footer from "@/components/Footer.vue";

export default {
    name: "AboutSubpage",
    props: ["charName"],
    components: { Footer },
    computed: {
        anchor() {
            return "#" + this.charName;
        },
    },
    methods: {
        openImage(src) {
            console.log('subpage openImage', src);
            // window.open('http://example.com/someImage.png');
        }
    },
};
</script>

<style lang="scss" scoped>
:deep img:not(.is-icon) {
    border-radius: 10px;
    border: 3px solid white;
    margin: 1rem 2rem 1rem 0;
    max-height: 350px;

    &.is-half {
        width: 45%;
    }
}

:deep .spoiler {
    position: relative;
    display: inline-block;
    overflow: hidden;
    cursor: pointer;

    border-radius: 10px;
    border: 3px solid white;
    margin: 1rem 2rem 1rem 0;

    &:after {
        content: "Spoiler";
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: white;
        border-radius: 1000px;
        padding: 1em 2em;
        background-color: rgb(0, 0, 0, 0.6);
    }

    img {
        margin: -4px;
        filter: blur(80px);
        -webkit-filter: blur(80px);
    }
}
</style>